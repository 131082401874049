function Hi() {
  return (
    <div
      style={{
        fontFamily: 'sans-serif',
        textAlign: 'center',
        marginTop: '45vh',
      }}
    >
      <h2>Hi there 👋</h2>
      <code>{`you probably came for /api/{id}`}</code>
    </div>
  )
}
export default Hi
